import * as React from "react";

import { StaticImage } from "gatsby-plugin-image";
import Page from "../../components/Layout/Page";
import CasestudiesCarousel from "../../components/CaseStudies";
import Customers from "../../components/Customers";
import Container from "../../components/Layout/Container";
import BtnFilled from "../../components/reusable/BtnFilled";
import ContactBlock from "../../components/ContactBlock";

import Seo from "../../components/reusable/Seo";
const CloudPage = () => {
  return (
    <Page className="solutions cloud-engg">
      <Seo
        title="Cloud Engineering"
        path="solutions/cloud-engineering"
        description=" Build a modern cloud foundation capable of supporting 
        AI-ML solutions at scale"
      />
      <section className="hero">
        <Container>
          <h1>
            Build stable, secure and hyper-available cloud infrastructure
            foundation for <i>intelligent solutions</i>
          </h1>
          <BtnFilled to="/contact-us" color="blue">
            Get in Touch
          </BtnFilled>
        </Container>
      </section>
      <section className="overview">
        <Container>
          <p className="intro">
            <b>
              Upgrade your enterprise infrastructure to drive AI capabilities
            </b>
          </p>
          <p>
            Modern Cloud Implementations and Cloud solutions built by Inviz are
            automated and repeatable, with Infrastructure as Code. Our cloud
            solutions are dynamically scalable, secure, optimized for cost and
            provide high availability and observability.
          </p>
          <p>
            We have built cloud platforms for MLOps, DataOps and Data Lake. Our
            platforms along with solution accelerators bring down set-up time
            and enables must-have functionalities such as – compliance,
            security, automation, scalability and high -availability to work out
            of the box.
          </p>
        </Container>
      </section>
      <section className="accelerators">
        <Container>
          <h2>
            <b>Modern Cloud Engineering done the right way</b>
          </h2>
          <div className="items">
            <div className="item">
              <div>
                <figure>
                  <StaticImage
                    src="../../images/solutions/cloud--automation.png"
                    alt="Cloud - End-to End Automation -  Inviz AI"
                    layout="fullWidth"
                    loading="lazy"
                    placeholder="none"
                  />
                </figure>
                <div className="text">
                  <h3>
                    <b>End-to End Automation</b>
                  </h3>
                  <p>
                    Cloud native application built the “correct way,” using
                    microservice architecture, IaC and devOps – automation
                    across the application lifecycle
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div>
                <figure>
                  <StaticImage
                    src="../../images/solutions/cloud--deployment.png"
                    alt="Modern Cloud Deployment - Inviz AI"
                    layout="fullWidth"
                    loading="lazy"
                    placeholder="none"
                  />
                </figure>
                <div className="text">
                  <h3>
                    <b>Modern Cloud Deployment</b>
                  </h3>
                  <p>
                    Cloud platforms and accelerators for MLOps, DataOps, Data
                    Lake for cloud agnostic implementation with infrastructure
                    as code for dynamic scaling, repeatability and to reduce
                    manual interventions
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div>
                <figure>
                  <StaticImage
                    src="../../images/solutions/cloud--repeatability.png"
                    alt="Cloud Deployment - Repeatability - Inviz AI"
                    layout="fullWidth"
                    loading="lazy"
                    placeholder="none"
                  />
                </figure>
                <div className="text">
                  <h3>
                    <b>Repeatability</b>
                  </h3>
                  <p>
                    Resilient multi-cloud architecture with elevated application
                    performance for end-users, zero downtime and other added
                    benefits.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className="case-studies">
        <Container>
          <h2>
            <b>Case Studies</b>
          </h2>
          <CasestudiesCarousel />
        </Container>
      </section>
      <section className="solutions">
        <Container>
          <h2>
            <b>Solutions</b>
          </h2>
          <div className="items">
            <div className="item">
              <div>
                <figure>
                  <StaticImage
                    src="../../images/solutions/graphics/solutions-vectors_cloud--enablement-managed-services.png"
                    alt="Cloud Enablement and Managed Services"
                    layout="fullWidth"
                    loading="lazy"
                    placeholder="blurred"
                  />
                </figure>
                <div className="text">
                  <h3>
                    <b>Cloud Platforms</b>
                  </h3>
                  <p>MLOps​ &#183; DataOps &#183; Data Lake</p>
                </div>
              </div>
            </div>
            <div className="item">
              <div>
                <figure>
                  <StaticImage
                    src="../../images/solutions/graphics/solutions-vectors_cloud--custom-implementation.png"
                    alt="Custom Cloud Implementation"
                    layout="fullWidth"
                    loading="lazy"
                    placeholder="blurred"
                  />
                </figure>
                <div className="text">
                  <h3>
                    <b>Modern Cloud Implementation</b>
                  </h3>
                  <p>
                    Automated &#183; Repeatable &#183; Infrastructure as Code
                  </p>
                </div>
              </div>
            </div>

            <div className="item">
              <div>
                <figure>
                  <StaticImage
                    src="../../images/solutions/graphics/solutions-vectors_cloud--security.png"
                    alt="Cloud Security"
                    layout="fullWidth"
                    loading="lazy"
                    placeholder="blurred"
                  />
                </figure>
                <div className="text">
                  <h3>
                    <b>Cloud Security &amp; Governance</b>
                  </h3>
                  <p>
                    Secrets Management &#183; Data Security &#183; Privacy
                    compliance
                  </p>
                </div>
              </div>
            </div>

            <div className="item">
              <div>
                <figure>
                  <StaticImage
                    src="../../images/solutions/graphics/solutions-vectors_cloud--migration.png"
                    alt="Cloud Migration"
                    layout="fullWidth"
                    loading="lazy"
                    placeholder="blurred"
                  />
                </figure>
                <div className="text">
                  <h3>
                    <b>Observability</b>
                  </h3>
                  <p>Monitoring​ &#183; Alerting</p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <ContactBlock>
        Talk to a modern cloud expert to find the right solution for your
        use-case
      </ContactBlock>
      <section className="clients">
        <Container>
          <h2>
            <b>Our Customers</b>
          </h2>
          <Customers />
        </Container>
      </section>
    </Page>
  );
};

export default CloudPage;
